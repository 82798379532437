<template>
	<div>
	<ContentHeader title="Data Surat" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="card">
              <div class="card-header">
                <h3 class="card-title">Data Surat</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: block;">
                <el-tabs v-model="activeTab" type="card" class="demo-tabs">
                    <el-tab-pane label="Surat Sehat" name="sehat">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Cari Data</span>
                                <input type="text" class="form-control " v-model="kata_kunci_sehat" @keyup="getDataResultSehat">
                            </div>
                            <div>
                                <el-button type="primary" @click="dialogVisible = true">Tambah</el-button>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col-lg-2">
                                <span>Jumlah Baris</span>
                                <select class="custom-select form-control" v-model="page_sehat" @change="getDataResultSehat">
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <span>Periode</span>
                                <select class="custom-select form-control" v-model="filter_periode.sehat">
                                    <option value="emptyParameter" disabled label="- Pilih Periode -"></option>
                                    <option value="tanggal" label="Tanggal"></option>
                                    <option value="bulan" label="Bulan"></option>
                                    <option value="tahun" label="Tahun"></option>
                                </select>
                            </div>
                            <div class="col-lg-7"> 
                                <el-date-picker v-if="filter_periode.sehat == 'tanggal'" class="mt-4 mr-3" value-format="YYYY-MM-DD" v-model="filter_value.sehat" format="DD/MM/YYYY" placeholder="Pilih Tanggal"></el-date-picker>
                                <el-select v-else-if="filter_periode.sehat == 'bulan'" v-model="filter_value.sehat" class="mt-4 mr-3" placeholder="Pilih Bulan">
                                    <el-option v-for="(row, index) in bulan" :key="index" :value="row.value" :label="row.label"></el-option>
                                </el-select>
                                <el-date-picker v-else-if="filter_periode.sehat == 'tahun'" v-model="filter_value.sehat" class="mt-4 mr-3" type="year" value-format="YYYY" placeholder="Pilih Tahun"></el-date-picker>
                                <el-button class="mt-4" :loading="memuat_data_sehat" @click="getDataResultSehat()">Cari</el-button>
                            </div>
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                        <thead>
                            <tr>
                                <th scope="col" class="text-sm" style="width: 80px;">No.</th>
                                <th scope="col" class="text-sm">Nomor Rekam Medis</th>
                                <th scope="col" class="text-sm">Nama Pasien</th>
                                <th scope="col" class="text-sm">Dokter</th>
                                <th scope="col" class="text-sm text-center" style="width: 100px;">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in data_result_sehat" :key="row.id">
                            <td class="text-sm" scope="row" style="width: 80px;">{{ index + 1 }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nomor_rekam_medis }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nama }}</td>
                            <td class="text-sm" scope="row">{{ row.dokter.nama_lengkap }}</td>
                            <td class="text-sm text-center" style="width: 100px;">
                                <i class="fas fa-download fa-lg ml-1" style="color: #039dc4;cursor: pointer;" @click="handleDownloadSurat(row.nama_dokumen, 'surat-sehat')"></i>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data_sehat">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data_sehat">Data Masih Kosong</p>
                        <nav aria-label="...">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: pagination_sehat.current_page == pagination_sehat.from}" @click="paginateDataSehat(pagination_sehat.prev_page_url)">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                </li>
                                <li class="page-item" :class="{ active: pagination_sehat.current_page == row.label }" @click="paginateDataSehat(row.url)" v-for="(row, index) in links_sehat" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                                <li class="page-item" :class="{ disabled: pagination_sehat.current_page == pagination_sehat.last_page}" @click="paginateDataSehat(pagination_sehat.next_page_url)">
                                <a class="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </el-tab-pane>
                    <el-tab-pane label="Surat Sakit" name="sakit">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Cari Data</span>
                                <input type="text" class="form-control " v-model="kata_kunci_sakit" @keyup="getDataResultSakit">
                            </div>
                            <div>
                                <el-button type="primary" @click="dialogVisibleSuratSakit = true">Tambah</el-button>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col-lg-2">
                                <span>Jumlah Baris</span>
                                <select class="custom-select form-control" v-model="page_sakit" @change="getDataResultSakit">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="45">45</option>
                                <option value="50">50</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <span>Periode</span>
                                <select class="custom-select form-control" v-model="filter_periode.sakit">
                                    <option value="emptyParameter" disabled label="- Pilih Periode -"></option>
                                    <option value="tanggal" label="Tanggal"></option>
                                    <option value="bulan" label="Bulan"></option>
                                    <option value="tahun" label="Tahun"></option>
                                </select>
                            </div>
                            <div class="col-lg-7"> 
                                <el-date-picker v-if="filter_periode.sakit == 'tanggal'" class="mt-4 mr-3" value-format="YYYY-MM-DD" v-model="filter_value.sakit" format="DD/MM/YYYY" placeholder="Pilih Tanggal"></el-date-picker>
                                <el-select v-else-if="filter_periode.sakit == 'bulan'" v-model="filter_value.sakit" class="mt-4 mr-3" placeholder="Pilih Bulan">
                                    <el-option v-for="(row, index) in bulan" :key="index" :value="row.value" :label="row.label"></el-option>
                                </el-select>
                                <el-date-picker v-else-if="filter_periode.sakit == 'tahun'" v-model="filter_value.sakit" class="mt-4 mr-3" type="year" value-format="YYYY" placeholder="Pilih Tahun"></el-date-picker>
                                <el-button class="mt-4" :loading="memuat_data_sakit" @click="getDataResultSakit()">Cari</el-button>
                            </div>
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                        <thead>
                            <tr>
                            <th scope="col" class="text-sm" style="width: 80px;">No.</th>
                            <th scope="col" class="text-sm">Nomor Rekam Medis</th>
                            <th scope="col" class="text-sm">Nama Pasien</th>
                            <th scope="col" class="text-sm">Dokter</th>
                            <th scope="col" class="text-sm text-center" style="width: 100px;">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in data_result_sakit" :key="row.id">
                            <td class="text-sm" scope="row" style="width: 80px;">{{ index + 1 }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nomor_rekam_medis }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nama }}</td>
                            <td class="text-sm" scope="row">{{ row.dokter.nama_lengkap }}</td>
                            <td class="text-sm text-center" style="width: 100px;">
                                <i class="fas fa-download fa-lg ml-1" style="color: #039dc4;cursor: pointer;" @click="handleDownloadSurat(row.nama_dokumen, 'surat-sakit')"></i>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data_sakit">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data_sakit">Data Masih Kosong</p>
                        <nav aria-label="...">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: pagination_sakit.current_page == pagination_sakit.from}" @click="paginateDataSakit(pagination_sakit.prev_page_url)">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                </li>
                                <li class="page-item" :class="{ active: pagination_sakit.current_page == row.label }" @click="paginateDataSakit(row.url)" v-for="(row, index) in links_sakit" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                                <li class="page-item" :class="{ disabled: pagination_sakit.current_page == pagination_sakit.last_page}" @click="paginateDataSakit(pagination_sakit.next_page_url)">
                                <a class="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </el-tab-pane>
                    <el-tab-pane label="Surat Rujukan" name="rujuan">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Cari Data</span>
                                <input type="text" class="form-control " v-model="kata_kunci_rujukan" @keyup="getDataResultRujukan">
                            </div>
                            <div>
                                <el-button type="primary" @click="dialogVisibleSuratRujukan = true">Tambah</el-button>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col-lg-2">
                                <span>Jumlah Baris</span>
                                <select class="custom-select form-control" v-model="page_rujukan" @change="getDataResultRujukan">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="45">45</option>
                                <option value="50">50</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <span>Periode</span>
                                <select class="custom-select form-control" v-model="filter_periode.rujukan">
                                    <option value="emptyParameter" disabled label="- Pilih Periode -"></option>
                                    <option value="tanggal" label="Tanggal"></option>
                                    <option value="bulan" label="Bulan"></option>
                                    <option value="tahun" label="Tahun"></option>
                                </select>
                            </div>
                            <div class="col-lg-7"> 
                                <el-date-picker v-if="filter_periode.rujukan == 'tanggal'" class="mt-4 mr-3" value-format="YYYY-MM-DD" v-model="filter_value.rujukan" format="DD/MM/YYYY" placeholder="Pilih Tanggal"></el-date-picker>
                                <el-select v-else-if="filter_periode.rujukan == 'bulan'" v-model="filter_value.rujukan" class="mt-4 mr-3" placeholder="Pilih Bulan">
                                    <el-option v-for="(row, index) in bulan" :key="index" :value="row.value" :label="row.label"></el-option>
                                </el-select>
                                <el-date-picker v-else-if="filter_periode.rujukan == 'tahun'" v-model="filter_value.rujukan" class="mt-4 mr-3" type="year" value-format="YYYY" placeholder="Pilih Tahun"></el-date-picker>
                                <el-button class="mt-4" :loading="memuat_data_rujukan" @click="getDataResultRujukan()">Cari</el-button>
                            </div>
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                        <thead>
                            <tr>
                            <th scope="col" class="text-sm" style="width: 80px;">No.</th>
                            <th scope="col" class="text-sm">Nomor Rekam Medis</th>
                            <th scope="col" class="text-sm">Nama Pasien</th>
                            <th scope="col" class="text-sm">Dokter</th>
                            <th scope="col" class="text-sm text-center" style="width: 100px;">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in data_result_rujukan" :key="row.id">
                            <td class="text-sm" scope="row" style="width: 80px;">{{ index + 1 }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nomor_rekam_medis }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nama }}</td>
                            <td class="text-sm" scope="row">{{ row.dokter.nama_lengkap }}</td>
                            <td class="text-sm text-center" style="width: 100px;">
                                <i class="fas fa-download fa-lg ml-1" style="color: #039dc4;cursor: pointer;" @click="handleDownloadSurat(row.nama_dokumen, 'surat-rujukan')"></i>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data_rujukan">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data_rujukan">Data Masih Kosong</p>
                        <nav aria-label="...">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: pagination_rujukan.current_page == pagination_rujukan.from}" @click="paginateDataRujukan(pagination_rujukan.prev_page_url)">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                </li>
                                <li class="page-item" :class="{ active: pagination_rujukan.current_page == row.label }" @click="paginateDataRujukan(row.url)" v-for="(row, index) in links_rujukan" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                                <li class="page-item" :class="{ disabled: pagination_rujukan.current_page == pagination_rujukan.last_page}" @click="paginateDataRujukan(pagination_rujukan.next_page_url)">
                                <a class="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </el-tab-pane>
                    <el-tab-pane label="Surat Buta Warna" name="buta_warna">
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <span>Cari Data</span>
                                <input type="text" class="form-control " v-model="kata_kunci_buta_warna" @keyup="getDataResultButaWarna">
                            </div>
                            <div>
                                <el-button type="primary" @click="dialogVisibleSuratButaWarna = true">Tambah</el-button>
                            </div>
                        </div>
                        <div class="row align-items-center mt-4">
                            <div class="col-lg-2">
                                <span>Jumlah Baris</span>
                                <select class="custom-select form-control" v-model="page_buta_warna" @change="getDataResultButaWarna">
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                            <div class="col-lg-3">
                                <span>Periode</span>
                                <select class="custom-select form-control" v-model="filter_periode.buta_warna">
                                    <option value="emptyParameter" disabled label="- Pilih Periode -"></option>
                                    <option value="tanggal" label="Tanggal"></option>
                                    <option value="bulan" label="Bulan"></option>
                                    <option value="tahun" label="Tahun"></option>
                                </select>
                            </div>
                            <div class="col-lg-7"> 
                                <el-date-picker v-if="filter_periode.buta_warna == 'tanggal'" class="mt-4 mr-3" value-format="YYYY-MM-DD" v-model="filter_value.buta_warna" format="DD/MM/YYYY" placeholder="Pilih Tanggal"></el-date-picker>
                                <el-select v-else-if="filter_periode.buta_warna == 'bulan'" v-model="filter_value.buta_warna" class="mt-4 mr-3" placeholder="Pilih Bulan">
                                    <el-option v-for="(row, index) in bulan" :key="index" :value="row.value" :label="row.label"></el-option>
                                </el-select>
                                <el-date-picker v-else-if="filter_periode.buta_warna == 'tahun'" v-model="filter_value.buta_warna" class="mt-4 mr-3" type="year" value-format="YYYY" placeholder="Pilih Tahun"></el-date-picker>
                                <el-button class="mt-4" :loading="memuat_data_buta_warna" @click="getDataResultButaWarna()">Cari</el-button>
                            </div>
                        </div>
                        <table class="table table-bordered table-hover mt-4">
                        <thead>
                            <tr>
                            <th scope="col" class="text-sm" style="width: 80px;">No.</th>
                            <th scope="col" class="text-sm">Nomor Rekam Medis</th>
                            <th scope="col" class="text-sm">Nama Pasien</th>
                            <th scope="col" class="text-sm">Dokter</th>
                            <th scope="col" class="text-sm text-center" style="width: 100px;">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in data_result_buta_warna" :key="row.id">
                            <td class="text-sm" scope="row" style="width: 80px;">{{ index + 1 }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nomor_rekam_medis }}</td>
                            <td class="text-sm" scope="row">{{ row.pasien.nama }}</td>
                            <td class="text-sm" scope="row">{{ row.dokter.nama_lengkap }}</td>
                            <td class="text-sm text-center" style="width: 100px;">
                                <i class="fas fa-download fa-lg ml-1" style="color: #039dc4;cursor: pointer;" @click="handleDownloadSurat(row.nama_dokumen, 'surat-tes-buta-warna')"></i>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <p class="text-center mt-2" v-if="memuat_data_buta_warna">Memuat Data...</p>
                        <p class="text-center mt-2" v-if="cek_data_buta_warna">Data Masih Kosong</p>
                        <nav aria-label="...">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: pagination_buta_warna.current_page == pagination_buta_warna.from}" @click="paginateDataButaWarna(pagination_buta_warna.prev_page_url)">
                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                </li>
                                <li class="page-item" :class="{ active: pagination_buta_warna.current_page == row.label }" @click="paginateDataButaWarna(row.url)" v-for="(row, index) in links_buta_warna" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
                                <li class="page-item" :class="{ disabled: pagination_buta_warna.current_page == pagination_buta_warna.last_page}" @click="paginateDataButaWarna(pagination_buta_warna.next_page_url)">
                                <a class="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </el-tab-pane>
                </el-tabs>
              </div>
              <!-- /.card-body -->
              <div class="card-footer" style="display: block;">
                
              </div>
              <!-- /.card-footer-->
            </div>
		</div>
	</div>

    <el-dialog
        v-model="dialogVisible"
        title="Tambah Surat Sehat"
        width="60%"
        :before-close="handleClose">
        <el-form ref="formRefSehat" :model="formSehat" label-position="left" label-width="150px">
            <el-form-item prop="tanggal_mulai" label="Tanggal Mulai" :rules="[ { required: true, message: 'Silahkan pilih tanggal mulai', trigger: 'change' } ]">
                <el-date-picker v-model="formSehat.tanggal_mulai" value-format="YYYY-MM-DD" format="DD-MM-YYYY" placeholder="Pilih Tanggal Mulai"></el-date-picker>
            </el-form-item>
            <el-form-item prop="pasien_id" label="Pasien" :rules="[ { required: true, message: 'Silahkan pilih pasien' } ]" class="w-100">
                <el-select id="pasien" v-model="formSehat.pasien_id" filterable remote reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100" :remote-method="getPasienRemote" :loading="loading_pasien">
                    <el-option v-for="(row, index) in pasien" :key="index" :label="customSelectPasien(row)" :value="row.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="dokter_id" label="Dokter" :rules="[ { required: true, message: 'Silahkan pilih dokter' } ]">
                <el-select v-model="formSehat.dokter_id" placeholder="Pilih Dokter" filterable :loading="loadingDokter"> 
                    <el-option v-for="(row, index) in dokter" :key="index" :value="row.id" :label="row.nama_lengkap"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="keperluan" label="Keperluan" :rules="[ { required: true, message: 'Silahkan isi keperluan', trigger: 'blur' } ]">
                <el-input v-model="formSehat.keperluan" type="textarea" maxlength="255" show-word-limit placeholder="Masukkan keperluan"></el-input>
            </el-form-item>
            <el-form-item prop="tekanan_darah" label="Tekanan Darah">
                <el-input type="text" v-model="formSehat.tekanan_darah"></el-input>
            </el-form-item>
            <el-form-item prop="tinggi_badan" label="Tinggi Badan (CM)">
                <el-input-number v-model="formSehat.tinggi_badan" min="0"></el-input-number>
            </el-form-item>
            <el-form-item prop="berat_badan" label="Berat Badan (KG)">
                <el-input-number v-model="formSehat.berat_badan" min="0"></el-input-number>
            </el-form-item>
            <el-form-item prop="catatan" label="Catatan">
                <el-input v-model="formSehat.catatan" type="textarea" maxlength="255" show-word-limit placeholder="Masukkan Catatan"></el-input>
            </el-form-item>
            <div class="d-flex justify-content-end">    
                <el-button @click="dialogVisible = false">Batal</el-button>
                <el-button type="primary" @click="submitSuratSehat" :loading="loadingSubmit">Simpan</el-button>
            </div>
        </el-form>
    </el-dialog>

    <el-dialog v-model="dialogVisibleSuratSakit" title="Tambah Surat Sakit" width="60%" :before-close="handleClose">
        <el-form ref="formRefSakit" :model="formSakit" label-position="left" label-width="150px">
            <el-form-item prop="pasien_id" label="Pasien" :rules="[ { required: true, message: 'Silahkan pilih pasien' } ]" class="w-100">
                <el-select id="pasien" v-model="formSakit.pasien_id" filterable remote reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100" :remote-method="getPasienRemote" :loading="loading_pasien">
                    <el-option v-for="(row, index) in pasien" :key="index" :label="customSelectPasien(row)" :value="row.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="dokter_id" label="Dokter" :rules="[ { required: true, message: 'Silahkan pilih dokter' } ]">
                <el-select v-model="formSakit.dokter_id" placeholder="Pilih Dokter" filterable :loading="loadingDokter"> 
                    <el-option v-for="(row, index) in dokter" :key="index" :value="row.id" :label="row.nama_lengkap"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="tanggal_mulai" label="Tanggal Mulai" :rules="[ { required: true, message: 'Silahkan pilih tanggal mulai', trigger: change } ]">
                <el-date-picker v-model="formSakit.tanggal_mulai" value-format="YYYY-MM-DD" format="DD-MM-YYYY" placeholder="Pilih Tanggal Mulai"></el-date-picker>
            </el-form-item>
            <el-form-item prop="tanggal_selesai" label="Tanggal Selesai" :rules="[ { required: true, message: 'Silahkan pilih tanggal selesai', trigger: change } ]">
                <el-date-picker v-model="formSakit.tanggal_selesai" value-format="YYYY-MM-DD" format="DD-MM-YYYY" placeholder="Pilih Tanggal Selesai"></el-date-picker>
            </el-form-item>
            <el-form-item prop="selama" label="Selama (Hari)" :rules="[ { required: true, message: 'Silahkan masukkan selama', trigger: change } ]">
                <el-input-number v-model="formSakit.selama" type="textarea"></el-input-number>
            </el-form-item>
            <div class="d-flex justify-content-end">    
                <el-button @click="dialogVisibleSuratSakit = false">Batal</el-button>
                <el-button type="primary" @click="submitSuratSakit" :loading="loadingSubmit">Simpan</el-button>
            </div>
        </el-form>
    </el-dialog>

    <el-dialog v-model="dialogVisibleSuratRujukan" title="Tambah Surat Rujukan" width="60%" :before-close="handleClose">
        <el-form ref="formRefRujukan" :model="formRujukan" label-position="left" label-width="200px">
            <el-form-item prop="pasien_id" label="Pasien" :rules="[ { required: true, message: 'Silahkan pilih pasien' } ]" class="w-100">
                <el-select id="pasien" v-model="formRujukan.pasien_id" filterable remote reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100" :remote-method="getPasienRemote" :loading="loading_pasien">
                    <el-option v-for="(row, index) in pasien" :key="index" :label="customSelectPasien(row)" :value="row.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="dokter_id" label="Dokter" :rules="[ { required: true, message: 'Silahkan pilih dokter' } ]">
                <el-select v-model="formRujukan.dokter_id" placeholder="Pilih Dokter" filterable :loading="loadingDokter"> 
                    <el-option v-for="(row, index) in dokter" :key="index" :value="row.id" :label="row.nama_lengkap"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="tujuan_kepada" label="Tujuan Kepada" :rules="[ { required: true, message: 'Silahkan masukkan tujuan kepada' } ]">
                <el-input v-model="formRujukan.tujuan_kepada" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="tempat_tujuan_kepada" label="Tempat Tujuan Kepada" :rules="[ { required: true, message: 'Silahkan masukkan tempat tujuan kepada' } ]">
                <el-input v-model="formRujukan.tempat_tujuan_kepada" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="anamnesa" label="Anamnesa" :rules="[ { required: true, message: 'Silahkan masukkan anamnesa' } ]">
                <el-input v-model="formRujukan.anamnesa" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="pemeriksaan_fisik" label="Pemeriksaan Fisik" :rules="[ { required: true, message: 'Silahkan masukkan pemeriksaan fisik' } ]">
                <el-input v-model="formRujukan.pemeriksaan_fisik" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="pemeriksaan_tambahan" label="Pemeriksaan Tambahan" :rules="[ { required: true, message: 'Silahkan masukkan pemeriksaan tambahan' } ]">
                <el-input v-model="formRujukan.pemeriksaan_tambahan" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="diagnosa_kerja" label="Diagnosa Kerja" :rules="[ { required: true, message: 'Silahkan masukkan diagnosa kerja' } ]">
                <el-input v-model="formRujukan.diagnosa_kerja" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="terapi" label="Terapi" :rules="[ { required: true, message: 'Silahkan masukkan terapi' } ]">
                <el-input v-model="formRujukan.terapi" type="textarea" maxlength="255" show-word-limit></el-input>
            </el-form-item>
            <div class="d-flex justify-content-end">    
                <el-button @click="dialogVisibleSuratRujukan = false">Batal</el-button>
                <el-button type="primary" @click="submitSuratRujukan" :loading="loadingSubmit">Simpan</el-button>
            </div>
        </el-form>
    </el-dialog>

    <el-dialog v-model="dialogVisibleSuratButaWarna" title="Tambah Surat Buta Warna" width="60%" :before-close="handleClose">
        <el-form ref="formRefButaWarna" :model="formButaWarna" label-position="left" label-width="200px">
            <el-form-item prop="pasien_id" label="Pasien" :rules="[ { required: true, message: 'Silahkan pilih pasien' } ]" class="w-100">
                <el-select id="pasien" v-model="formButaWarna.pasien_id" filterable remote reserve-keyword placeholder="Masukkan Nama Pasien" class="w-100" :remote-method="getPasienRemote" :loading="loading_pasien">
                    <el-option v-for="(row, index) in pasien" :key="index" :label="customSelectPasien(row)" :value="row.id" />
                </el-select>
            </el-form-item>
            <el-form-item prop="dokter_id" label="Dokter" :rules="[ { required: true, message: 'Silahkan pilih dokter' } ]">
                <el-select v-model="formButaWarna.dokter_id" placeholder="Pilih Dokter" filterable :loading="loadingDokter"> 
                    <el-option v-for="(row, index) in dokter" :key="index" :value="row.id" :label="row.nama_lengkap"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="hasil" label="Mengalami Buta Warna">
                <el-switch v-model="formButaWarna.hasil" class="mb-2" active-text="Ya" inactive-text="Tidak" inactive-value="0" active-value="1"/>
            </el-form-item>
            <div class="d-flex justify-content-end">    
                <el-button @click="dialogVisibleSuratButaWarna = false">Batal</el-button>
                <el-button type="primary" @click="submitSuratButaWarna" :loading="loadingSubmit">Simpan</el-button>
            </div>
        </el-form>
    </el-dialog>

    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'

import axios from 'axios'
import { ref, computed, reactive, onMounted, watch } from 'vue'
import store from '@/store'
import Swal from 'sweetalert2'
import { ElMessageBox, ElMessage } from 'element-plus'
import umur from '@/umur.js'

const activeTab = ref('sehat')

// Data
const cabang        = computed(() => store.getters['auth/cabang'])
const user          = computed(() => store.getters['auth/user'])
const pasien        = computed(() => store.getters['pasien/getPasien'])
const dokter        = computed(() => store.getters['karyawan/getKaryawan'])
const loadingDokter = computed(() => store.getters['pasien/getLoading'])
const loadingSubmit = ref(false)
const loading_pasien = ref(false)

// Fetching data
const getPasienRemote = (val) => {
    loading_pasien.value = true
    store.dispatch('pasien/fetchPasien', { id: cabang.value.id, tipe_cabang: cabang.value.tipe, keyword: val })
    .finally(() => {
        loading_pasien.value = false
    })
}
store.dispatch('karyawan/fetchDokter', cabang.value.id)

// Dialog form
const dialogVisible               = ref(false)
const dialogVisibleSuratSakit     = ref(false)
const dialogVisibleSuratRujukan   = ref(false)
const dialogVisibleSuratButaWarna = ref(false)
const handleClose = () => {
  ElMessageBox.confirm('Tutup tampilan?')
    .then(() => {
      dialogVisible.value               = false
      dialogVisibleSuratSakit.value     = false
      dialogVisibleSuratRujukan.value   = false
      dialogVisibleSuratButaWarna.value = false
    })
    .catch(() => {
      // catch error
    })
}

// Data Filter
const filter_periode = reactive({
    sehat: 'emptyParameter',
    sakit: 'emptyParameter',
    rujukan: 'emptyParameter',
    buta_warna: 'emptyParameter',
})

const filter_value = reactive({
    sehat: null,
    sakit: null,
    rujukan: null,
    buta_warna: null,
})
watch(() => filter_periode.sehat, () => filter_value.sehat = null)
watch(() => filter_periode.sakit, () => filter_value.sakit = null)
watch(() => filter_periode.rujukan, () => filter_value.rujukan = null)
watch(() => filter_periode.buta_warna, () => filter_value.buta_warna = null)

const bulan = ref([
    { value: '01', label:'Januari' },
    { value: '02', label:'Februari' },
    { value: '03', label:'Maret' },
    { value: '04', label:'April' },
    { value: '05', label:'Mei' },
    { value: '06', label:'Juni' },
    { value: '07', label:'Juli' },
    { value: '08', label:'Agustus' },
    { value: '09', label:'September' },
    { value: '10', label:'Oktober' },
    { value: '11', label:'November' },
    { value: '12', label:'Desember' },
])

// Data form
const formRefSehat = ref()
const formRefSakit = ref()
const formRefRujukan = ref()
const formRefButaWarna = ref()
const formSehat = reactive({
    cabang_id       : cabang.value.id,
    tanggal_sehat   : null,
    pasien_id       : null,
    dokter_id       : null,
    keperluan       : null,
    tekanan_datah   : null,
    tinggi_badan    : null,
    berat_badan     : null,
    catatan         : null,
    pilihan_cetak   : null,
})

const formSakit = reactive({
    cabang_id       : cabang.value.id,
    pasien_id       : null,
    dokter_id       : null,
    tanggal_mulai   : null,
    tanggal_selesai : null,
    selama          : null,
})

const formRujukan = reactive({
    cabang_id           : cabang.value.id,
    pasien_id           : null,
    dokter_id           : null,
    tujuan_kepada       : null,
    tempat_tujuan_kepada: null,
    anamnesa            : null,
    pemeriksaan_fisik   : null,
    pemeriksaan_tambahan: null,
    diagnosa_kerja      : null,
    terapi              : null
})

const formButaWarna = reactive({
    cabang_id       : cabang.value.id,
    pasien_id       : null,
    dokter_id       : null,
    hasil           : '0',
})

const customSelectPasien = ({ nomor_rekam_medis, nama, alamat_lengkap, tanggal_lahir }) => {
    tanggal_lahir = tanggal_lahir ? `${umur(tanggal_lahir)} Tahun - ` : ''
    return `${nomor_rekam_medis} - ${nama} - ${tanggal_lahir} ${alamat_lengkap}`
}

// Handle Submit
const submitSuratSehat = () => {
    formRefSehat.value.validate((valid) => {
        if(valid) {
            loadingSubmit.value = true
            axios.post(`api/surat/createSuratSehat`, formSehat)
            .then(() => {
                dialogVisible.value = false
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menambahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })
                getDataResultSehat()
            })
            .catch(() => {
                ElMessage.error('Gagal menambahkan data.')
            })
            .finally(() => {
                loadingSubmit.value = false
            })
        } else {
            return false
        }
    })
}

const submitSuratSakit = () => {
    formRefSakit.value.validate((valid) => {
        if(valid) {
            loadingSubmit.value = true
            axios.post(`api/surat/createSuratSakit`, formSakit)
            .then(() => {
                dialogVisibleSuratSakit.value = false
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menambahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })
                getDataResultSakit()
            })
            .catch(() => {
                ElMessage.error('Gagal menambahkan data.')
            })
            .finally(() => {
                loadingSubmit.value = false
            })
        } else {
            return false
        }
    })
}

const submitSuratRujukan = () => {
    formRefRujukan.value.validate((valid) => {
        if(valid) {
            loadingSubmit.value = true
            axios.post(`api/surat/createSuratRujukan`, formRujukan)
            .then(() => {
                dialogVisibleSuratRujukan.value = false
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menambahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })
                getDataResultRujukan()
            })
            .catch(() => {
                ElMessage.error('Gagal menambahkan data.')
            })
            .finally(() => {
                loadingSubmit.value = false
            })
        } else {
            return false
        }
    })
}

const submitSuratButaWarna = () => {
    formRefButaWarna.value.validate((valid) => {
        if(valid) {
            loadingSubmit.value = true
            axios.post(`api/surat/createSuratButaWarna`, formButaWarna)
            .then(() => {
                dialogVisibleSuratButaWarna.value = false
                Swal.fire({
                  title: 'Berhasil!',
                  text: 'Berhasil Menambahkan Data',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500
                })
                getDataResultButaWarna()
            })
            .catch(() => {
                ElMessage.error('Gagal menambahkan data.')
            })
            .finally(() => {
                loadingSubmit.value = false
            })
        } else {
            return false
        }
    })
}


// Paginate Surat Sehat
const data_result_sehat = ref([])
const cek_data_sehat = ref(false)
const page_sehat = ref(10)
const links_sehat = ref([])
const pagination_sehat = ref([])
const memuat_data_sehat = ref(false)
const kata_kunci_sehat = ref('')
const cabang_select_value_sehat = ref('')

const getDataResultSehat = async () => {
    data_result_sehat.value = []
    memuat_data_sehat.value = true
    cek_data_sehat.value = false

    let cabang_id = ''

    if (cabang_select_value_sehat.value == '') {
    cabang_id = user.value.cabang_id
    } else {
    cabang_id = cabang_select_value_sehat.value.id
    }
    let filterValue = filter_value.sehat ? filter_value.sehat : 'emptyParameter' 

    let { data } = await axios.get(`api/surat/getSuratSehatPaginate/${cabang_id}/${page_sehat.value}/${filter_periode.sehat}/${filterValue}`)

    if (data != 'kosong') {
        memuat_data_sehat.value = false
        cek_data_sehat.value = false
        data_result_sehat.value = data.data
        links_sehat.value = data.links
        links_sehat.value.splice(0, 1)
        links_sehat.value.splice(links_sehat.value.length-1, 1)
        pagination_sehat.value = data
    } else {
        cek_data_sehat.value = true
        memuat_data_sehat.value = false
        data_result_sehat.value = []
    }
}

const paginateDataSehat = async (url) => {

    if (url != null) {
        data_result_sehat.value = []
        memuat_data_sehat.value = true

        let link_url = new URL(url)
        link_url = `${link_url.pathname}${link_url.search}`
        
        let { data } = await axios.get(`${link_url}`)

        
        if (data != 'kosong') {
        memuat_data_sehat.value = false
        data_result_sehat.value = data.data 
        links_sehat.value = data.links
        links_sehat.value.splice(0, 1)
        links_sehat.value.splice(links_sehat.value.length-1, 1)
        pagination_sehat.value = data
        }
    }
}

// Paginate Surat Sakit
const data_result_sakit = ref([])
const cek_data_sakit = ref(false)
const page_sakit = ref(10)
const links_sakit = ref([])
const pagination_sakit = ref([])
const memuat_data_sakit = ref(false)
const kata_kunci_sakit = ref('')
const cabang_select_value_sakit = ref('')

const getDataResultSakit = async () => {
    data_result_sakit.value = []
    memuat_data_sakit.value = true
    cek_data_sakit.value = false

    let cabang_id_sakit = ''

    if (cabang_select_value_sakit.value == '') {
    cabang_id_sakit = user.value.cabang_id
    } else {
    cabang_id_sakit = cabang_select_value_sakit.value.id
    }
    
    let filterValue = filter_value.sakit ? filter_value.sakit : 'emptyParameter' 

    let { data } = await axios.get(`api/surat/getSuratSakitPaginate/${cabang_id_sakit}/${page_sakit.value}/${filter_periode.sakit}/${filterValue}`)

    if (data != 'kosong') {
        memuat_data_sakit.value = false
        cek_data_sakit.value = false
        data_result_sakit.value = data.data
        links_sakit.value = data.links
        links_sakit.value.splice(0, 1)
        links_sakit.value.splice(links_sakit.value.length-1, 1)
        pagination_sakit.value = data
    } else {
        cek_data_sakit.value = true
        memuat_data_sakit.value = false
        data_result_sakit.value = []
    }
}

const paginateDataSakit = async (url) => {

    if (url != null) {
        data_result_sakit.value = []
        memuat_data_sakit.value = true

        let link_url = new URL(url)
        link_url = `${link_url.pathname}${link_url.search}`
        
        let { data } = await axios.get(`${link_url}`)

        
        if (data != 'kosong') {
        memuat_data_sakit.value = false
        data_result_sakit.value = data.data 
        links_sakit.value = data.links
        links_sakit.value.splice(0, 1)
        links_sakit.value.splice(links_sakit.value.length-1, 1)
        pagination_sakit.value = data
        }
    }
}

// Paginate Surat Rujukan
const data_result_rujukan = ref([])
const cek_data_rujukan = ref(false)
const page_rujukan = ref(10)
const links_rujukan = ref([])
const pagination_rujukan = ref([])
const memuat_data_rujukan = ref(false)
const kata_kunci_rujukan = ref('')
const cabang_select_value_rujukan = ref('')

const getDataResultRujukan = async () => {
    data_result_rujukan.value = []
    memuat_data_rujukan.value = true
    cek_data_rujukan.value = false

    let cabang_id_rujukan = ''

    if (cabang_select_value_rujukan.value == '') {
    cabang_id_rujukan = user.value.cabang_id
    } else {
    cabang_id_rujukan = cabang_select_value_rujukan.value.id
    }

    let filterValue = filter_value.rujukan ? filter_value.rujukan : 'emptyParameter' 

    let { data } = await axios.get(`api/surat/getSuratRujukanPaginate/${cabang_id_rujukan}/${page_rujukan.value}/${filter_periode.rujukan}/${filterValue}`)

    if (data != 'kosong') {
        memuat_data_rujukan.value = false
        cek_data_rujukan.value = false
        data_result_rujukan.value = data.data
        links_rujukan.value = data.links
        links_rujukan.value.splice(0, 1)
        links_rujukan.value.splice(links_rujukan.value.length-1, 1)
        pagination_rujukan.value = data
    } else {
        cek_data_rujukan.value = true
        memuat_data_rujukan.value = false
        data_result_rujukan.value = []
    }
}

const paginateDataRujukan = async (url) => {

    if (url != null) {
        data_result_rujukan.value = []
        memuat_data_rujukan.value = true

        let link_url = new URL(url)
        link_url = `${link_url.pathname}${link_url.search}`
        
        let { data } = await axios.get(`${link_url}`)

        
        if (data != 'kosong') {
        memuat_data_rujukan.value = false
        data_result_rujukan.value = data.data 
        links_rujukan.value = data.links
        links_rujukan.value.splice(0, 1)
        links_rujukan.value.splice(links_rujukan.value.length-1, 1)
        pagination_rujukan.value = data
        }
    }
}

// Paginate Surat ButaWarna
const data_result_buta_warna = ref([])
const cek_data_buta_warna = ref(false)
const page_buta_warna = ref(10)
const links_buta_warna = ref([])
const pagination_buta_warna = ref([])
const memuat_data_buta_warna = ref(false)
const kata_kunci_buta_warna = ref('')
const cabang_select_value_buta_warna = ref('')

const getDataResultButaWarna = async () => {
    data_result_buta_warna.value = []
    memuat_data_buta_warna.value = true
    cek_data_buta_warna.value = false

    let cabang_id_buta_warna = ''

    if (cabang_select_value_buta_warna.value == '') {
    cabang_id_buta_warna = user.value.cabang_id
    } else {
    cabang_id_buta_warna = cabang_select_value_buta_warna.value.id
    }

    let filterValue = filter_value.buta_warna ? filter_value.buta_warna : 'emptyParameter' 

    let { data } = await axios.get(`api/surat/getSuratButaWarnaPaginate/${cabang_id_buta_warna}/${page_buta_warna.value}/${filter_periode.buta_warna}/${filterValue}`)

    if (data != 'kosong') {
        memuat_data_buta_warna.value = false
        cek_data_buta_warna.value = false
        data_result_buta_warna.value = data.data
        links_buta_warna.value = data.links
        links_buta_warna.value.splice(0, 1)
        links_buta_warna.value.splice(links_buta_warna.value.length-1, 1)
        pagination_buta_warna.value = data
    } else {
        cek_data_buta_warna.value = true
        memuat_data_buta_warna.value = false
        data_result_buta_warna.value = []
    }
}

const paginateDataButaWarna = async (url) => {

    if (url != null) {
        data_result_buta_warna.value = []
        memuat_data_buta_warna.value = true

        let link_url = new URL(url)
        link_url = `${link_url.pathname}${link_url.search}`
        
        let { data } = await axios.get(`${link_url}`)

        
        if (data != 'kosong') {
        memuat_data_buta_warna.value = false
        data_result_buta_warna.value = data.data 
        links_buta_warna.value = data.links
        links_buta_warna.value.splice(0, 1)
        links_buta_warna.value.splice(links_buta_warna.value.length-1, 1)
        pagination_buta_warna.value = data
        }
    }
}

// Action View and Download
const handleDownloadSurat = async (nama_dokumen, nama_file) => {
    await axios.get(`api/surat/getDokumenSurat/${nama_dokumen}`, {responseType: "blob"})
    .then((response) => {
        console.log(response)
        let blob    = new Blob([response.data], {type: 'application/msword'});
        let url         = window.URL || window.webkitURL;
        let fileUrl     = url.createObjectURL(blob)
        let element     = document.createElement('a')
        element.href    = fileUrl;
        element.setAttribute('download',`${nama_file}.docx`)
        document.body.appendChild(element)
        element.click()
        window.URL.revokeObjectURL(blob)
    })
    .catch(() => {
        ElMessage.error('Dokumen Tidak Ditemukan.')
    })
}
onMounted(() => {
    getDataResultSehat()
    getDataResultSakit()
    getDataResultRujukan()
    getDataResultButaWarna()
})
</script>